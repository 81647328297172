import React from "react";
import logo from "../image/Fill 219.png"
 export default function Main(props){
     return(
        <div className="content">
           
        <img src={props.info.imageUrl}  className="placeImage"/>
        <div className="location">
            <img src={logo} />
            <p>{props.info.location}</p>
            <a href={props.info.googleMapUrl} > View on Google Maps</a> 
        </div>
        <div className="description">
            <h2>{props.info.title}</h2>
            <div>{props.info.startDate}-{props.info.endDate}</div>
            <p>{props.info.description}</p>
        </div>
        
    </div>
     )
 }