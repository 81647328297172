import React from "react";
import Narbar from "./component/Navbar"
import Main from "./component/Main"
import data from "./data"
export default function App(){
  const tdata = data.map(info => {
    return(
          <Main 
            info = {info}
          />
    )
  }) 
  return(
      <div>
      <Narbar />
      <section>
        {tdata}
      </section>
      </div>
  )
}
